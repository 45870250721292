import React from 'react'
import { Link } from 'gatsby'
import Container from '../components/container'
import Helmet from 'react-helmet'
import Layout from '../containers/layout'
import SEO from '../components/seo'
import '../components/policy.css'

const Policy = () => {


  return (
    <Layout>
      <Container>
        <SEO title={'Legal'} description={""}/>
        <Helmet>
          <meta name={`robots`} content={`noindex, nofollow`} />
        </Helmet>

        <div className="legal_list"> 
          <Link to='/terms-conditions' target="_blank">Terms &amp; Conditions</Link>
          <Link to='/privacy-policy' target="_blank">Privacy Policy</Link>
          <Link to='/cookie-policy' target="_blank">Cookie Policy</Link>
        </div>

      </Container>
    </Layout>
  )
}

export default Policy




